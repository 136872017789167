import { EnvironmentInterface } from './environment-interface';

export const environment: EnvironmentInterface = {
  authentication: {
    clientId: '9d48c57f-73d8-4a26-ae2b-33e367b16f8c',
    authority:
      'https://login.microsoftonline.com/329e91b0-e21f-48fb-a071-456717ecc28e',
    scopes: ['api://be108472-7c90-4178-89de-dffb5d22d6c9/Back.Read'],
    redirectUri: '/',
  },
  baseUrl: 'https://dev.api.rtpca.iasp.tgscloud.net/api/v1',
  appInsightKey: 'f634dda0-6ed9-4b8c-9c79-c04867755bcc',
};
